import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import {
  Create,
  email,
  maxLength,
  minLength,
  number,
  PasswordInput,
  RadioButtonGroupInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { SectionTitle } from "../../components/SectionTitle";
import { allCountries, supportedCurrencies } from "../../helpers/choices";
import {
  passwordMatch,
  passwordNoValidation,
  validateNoSpecialChars,
} from "../../utils/validations";
import { useStyles } from "./styles";

const validateGlobal = (values) => {
  const errors = {};
  if (values?.password !== values?.confirm_password) {
    errors.confirm_password = "resources.register.passwordMatchError";
  }
  if (
    values?.userLogin &&
    values.userLogin.toLowerCase() !== values.userLogin
  ) {
    errors.userLogin = "resources.register.usernameError";
  }
  return errors;
};
const usernameValidate = [
  required(),
  minLength(5),
  maxLength(50),
  validateNoSpecialChars,
];
const telephoneValidate = [required(), number(), minLength(6), maxLength(20)];
const requiredValidate = [required()];
const required40Chars = [required(), maxLength(40), validateNoSpecialChars];
const required30Chars = [required(), maxLength(30), validateNoSpecialChars];
const required7Chars = [required(), maxLength(7), validateNoSpecialChars];

const CreateForm = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onFailure = (resp) => {
    if (resp.status === 400) {
      let message = resp?.body?.fieldErrors?.map((i) => i.field.toUpperCase());

      message.map((i) => {
        if (i === "TELEPHONE") {
          notify("resources.register.phoneError", { type: "warning" });
        }
        if (i === "USERLOGIN") {
          notify("resources.register.userLoginError", { type: "warning" });
        }
        if (i === "LOGIN") {
          notify("resources.register.userLoginInUseError", { type: "warning" });
        }
        if (i === "EMAIL") {
          notify("resources.register.emailInUseError", { type: "warning" });
        }
        if (i === "COMPANY") {
          notify("resources.register.companyNameInUseError", {
            type: "warning",
          });
        }
      });
    } else {
      notify("ra.notification.http_error", { type: "warning" });
    }
  };
  const onSuccess = (resp) => {
    if (resp.status === 201) {
      notify("ra.notification.created", { type: "success" });
      redirect("/registration-saved");
    }
  };

  return (
    <Create
      classes={{
        card: classes.paper,
      }}
      redirect={false}
      onFailure={onFailure}
      onSuccess={onSuccess}
      resource="register"
      title={"resources.register.title"}
      {...props}
      {...props}
      basePath="/create-profile"
      {...props}
    >
      <SimpleForm
        validate={validateGlobal}
        toolbar={
          <CustomToolbar
            handleSubmitWithRedirect={"list"}
            label="resources.register.submitButton"
          />
        }
      >
        <Grid className={classes.titleForm}>
          {translate("resources.register.title")}
        </Grid>
        <Grid
          container
          className={classes.root}
          direction="column"
          justifyContent="space-between"
        >
          <SectionTitle
            className={classes.title}
            label="resources.register.basicInfo"
          />
          <Box className={classes.wrapper}>
            <Grid className={classes.sectionWrapper}>
              <TextInput
                resource={"register"}
                source="contactPersonFirstName"
                placeholder="Enter your first name here"
                className={classes.inputMedium}
                validate={required40Chars}
                inputProps={{
                  maxLength: 40,
                }}
              />
              <TextInput
                resource={"register"}
                placeholder="Enter your last name here"
                source="contactPersonLastName"
                className={classes.inputMedium}
                validate={required40Chars}
                inputProps={{
                  maxLength: 40,
                }}
              />
              <TextInput
                resource={"register"}
                source="userLogin"
                placeholder="Enter your username here"
                className={classes.inputMedium}
                validate={usernameValidate}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid justifyContent="flex-end" className={classes.sectionWrapper}>
              <PasswordInput
                resource={"register"}
                source="password"
                className={classes.password}
                validate={[passwordNoValidation]}
              />
              <PasswordInput
                resource={"register"}
                label="resources.register.fields.confirmPassword"
                source="confirm_password"
                className={classes.password}
                validate={(value, allValues) =>
                  passwordMatch(value, allValues?.password)
                }
              />
            </Grid>
          </Box>

          <SectionTitle
            className={classes.title}
            label="resources.register.companyInfo"
          />
          <Box className={classes.wrapper}>
            <TextInput
              resource={"register"}
              source="companyName"
              style={{ marginRight: 16 }}
              className={classes.inputMedium}
              validate={required30Chars}
              inputProps={{
                maxLength: 30,
              }}
            />
            <TextInput
              resource={"register"}
              source="address.address"
              className={classes.inputMedium}
              validate={required30Chars}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Box>
          <Box className={classes.wrapper}>
            <Box width={"50%"} mr={2}>
              <TextInput
                resource={"register"}
                source="address.state"
                className={classes.inputMedium}
                validate={required30Chars}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </Box>
            <Box className={classes.fieldWrapper}>
              <TextInput
                style={{ marginRight: 16 }}
                resource={"register"}
                source="address.city"
                margin={"dense"}
                className={classes.inputMedium}
                validate={required30Chars}
                inputProps={{
                  maxLength: 30,
                }}
              />
              <TextInput
                resource={"register"}
                source="address.postalCode"
                margin={"dense"}
                className={classes.inputMedium}
                validate={required7Chars}
                inputProps={{
                  maxLength: 7,
                }}
              />
            </Box>
          </Box>
          <Box className={classes.fieldWrapper}>
            <SelectInput
              resource={"register"}
              source="address.countryIso3Code"
              style={{ marginRight: 16 }}
              className={classes.inputMedium}
              choices={allCountries}
              optionText="name"
              optionValue="code"
              validate={requiredValidate}
            />
            <SelectInput
              resource={"register"}
              source="currency"
              style={{ marginRight: 8 }}
              className={classes.inputMedium}
              choices={supportedCurrencies}
              optionText="fullDescription"
              optionValue="code"
              validate={requiredValidate}
            />
          </Box>
          <SectionTitle
            className={classes.title}
            label="resources.register.contactInfo"
          />
          <Box className={classes.wrapper}>
            <TextInput
              style={{ marginRight: 16 }}
              label="resources.register.fields.phoneNumber"
              source="telephone"
              className={classes.inputMedium}
              validate={telephoneValidate}
            />
            <TextInput
              resource={"register"}
              source="faxNumber"
              className={classes.inputMedium}
              validate={[maxLength(10)]}
              inputProps={{
                maxLength: 10,
              }}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <TextInput
              style={{ marginRight: 8 }}
              resource={"register"}
              type="email"
              source="email"
              validation={{ email: true }}
              className={classes.inputMedium}
              validate={[required(), email(), maxLength(64)]}
              inputProps={{
                maxLength: 64,
              }}
            />
          </Box>
          <RadioButtonGroupInput
            resource={"register"}
            source="receiveNewsletter"
            choices={[
              { checked: true, name: "resources.register.fields.yes" },
              { checked: false, name: "resources.register.fields.no" },
            ]}
            optionText="name"
            optionValue="checked"
          />
        </Grid>
      </SimpleForm>
    </Create>
  );
};
export default CreateForm;
