import { Box, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import classnames from 'classnames';
import * as React from 'react';
import { PasswordInput, SimpleForm, useNotify, useTranslate } from 'react-admin';
import { CustomToolbar } from "../../../components/CustomToolbar";
import { SectionTitle } from "../../../components/SectionTitle";
import palette from "../../../styles/palette";
import { passwordMatch, passwordValidation } from '../../../utils/validations';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
        "& .MuiButton-containedPrimary": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            borderRadius: "20px",
        }
    },

    form: {
        maxWidth: 760,
        marginLeft: theme.spacing(9),
        marginTop: theme.spacing(8),
        border: " 1px solid #0AA5B7",
        borderRadius: "5px",
        "& .MuiCardContent-root": {
            padding: theme.spacing(8),
        },
    },

    password: {
        maxWidth: 520,
        width: "100%",
        "& .MuiFilledInput-input": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "15px 15px 0px 0px"
        },
        "& .MuiFilledInput-root": {
            borderRadius: "15px 15px 0px 0px",
            backgroundColor: theme.palette.primary.light,
        },
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    titleForm: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '1px solid #0AA5B7',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontWeight: 700,
        color: palette.primary.main,
        fontSize: '1.5rem',
    },
}));
const validatePassword = (values) => {
    const errors = {};
    if (values.password !== values.confirm_password) {
        errors.confirm_password = "changePasswordForm.confirmPasswordError";
    }
    if (values.password === values.currentPassword) {
        errors.password = 'changePasswordForm.newPasswordError';
    }
    return errors
};
const AdminForm = () => {
    const classes = useStyles();
    const notify = useNotify();
    const translate = useTranslate()

    const handleSubmit = (values) => {

        const request = ({
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: ({'Content-Type': 'application/json;charset=utf-8'}),
            data: {
                oldPassword:values.currentPassword.toString(),
                newPassword:values.password.toString()},

        });
        axios(`${window._env_.REACT_APP_API_HOST}` + '/merchant/api/account/change-password', request,)
            .then(res => {
                res.status === 200 ? notify("Password successfully updated") : notify('Error');
            })
            .catch(err => {
               (err?.response?.data?.message === "User password does not match the entered password") && notify(translate('changePasswordForm.wrongCurrentPassword'), {type: 'warning',});
            })
    };
    return (
        <SimpleForm
            validate={validatePassword}
            save={handleSubmit}
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar handleSubmitWithRedirect={'list'}/>}
            className={classes.form}>
            <Grid className={classes.titleForm}>
                {translate('changePasswordForm.title')}
            </Grid>
            <SectionTitle
                className={classes.title}
                label="changePasswordForm.changePassword"/>
            <PasswordInput
                source="currentPassword"
                className={classes.password}
                label={translate('changePasswordForm.currentPassword')}
            />
            <Separator/>
            <PasswordInput
                source="password"
                label={translate('changePasswordForm.newPassword')}
                className={classes.password}
                validate={[passwordValidation]}
            />
            <PasswordInput
                source="confirm_password"
                label={translate('changePasswordForm.confirmPassword')}
                className={classnames(classes.password)}
                validate={(value, allValues) => passwordMatch(value, allValues?.password)}
            />
        </SimpleForm>
    )
};

const Separator = () => <Box pt="1em"/>;
export default AdminForm;