import * as React from "react";
import {
  email,
  maxLength,
  number,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import { Box, Grid } from "@material-ui/core";
import { SectionTitle } from "../../../components/SectionTitle";
import { useStyles } from "./styles";
import {
  allCountries,
  paymentType,
  supportedCurrencies,
} from "../../../helpers/choices";
import { validateNoSpecialChars } from "../../../utils/validations";

const ViewDetailsForm = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Box width={"70%"}>
        <SectionTitle
          className={classes.title}
          label="resources.merchants.forms.basicInfo"
        />
        <Box className={classes.wrapper}>
          <TextInput
            resource={"merchants"}
            source="contactPersonFirstName"
            className={classes.inputMedium}
            validate={required40Chars}
            inputProps={{
              maxLength: 40,
            }}
          />
          <TextInput
            resource={"merchants"}
            source="contactPersonLastName"
            className={classes.inputMedium}
            validate={required40Chars}
            inputProps={{
              maxLength: 40,
            }}
          />
        </Box>
        <SectionTitle
          className={classes.title}
          label="resources.merchants.forms.companyInfo"
        />
        <Box className={classes.wrapper}>
          <TextInput
            source="companyName"
            resource={"merchants"}
            className={classes.inputMedium}
            validate={required30Chars}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextInput
            resource={"merchants"}
            source="address"
            className={classes.inputMedium}
            validate={required30Chars}
            inputProps={{
              maxLength: 30,
            }}
          />
        </Box>
        <Box className={classes.wrapper}>
          <Box width={"50%"} mr={2}>
            <TextInput
              margin={"dense"}
              resource={"merchants"}
              source="state"
              className={classes.inputMedium}
              validate={required30Chars}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <TextInput
              resource={"merchants"}
              source="city"
              margin={"dense"}
              className={classes.inputSmall}
              validate={required30Chars}
              inputProps={{
                maxLength: 30,
              }}
            />
            <TextInput
              resource={"merchants"}
              source="postalCode"
              margin={"dense"}
              className={classes.postcode}
              validate={required7Chars}
              inputProps={{
                maxLength: 7,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.wrapper}>
          <SelectInput
            resource={"merchants"}
            source="countryIso3Code"
            choices={allCountries}
            optionText="name"
            optionValue="code"
            className={classes.inputMedium}
            validate={requiredValidate}
          />
          <SelectInput
            resource={"merchants"}
            source="currency"
            choices={supportedCurrencies}
            optionText="fullDescription"
            optionValue="code"
            className={classes.inputMedium}
            validate={requiredValidate}
          />
        </Box>
        <SectionTitle
          className={classes.title}
          label="resources.merchants.forms.contactInfo"
        />
        <Box className={classes.wrapper}>
          <TextInput
            resource={"merchants"}
            source="phoneNumber"
            className={classes.inputMedium}
            validate={[
              required(),
              maxLength(15),
              validateNoSpecialChars,
              number(),
            ]}
            inputProps={{
              maxLength: 15,
            }}
          />
          <TextInput
            resource={"merchants"}
            source="faxNumber"
            className={classes.inputMedium}
            validate={[maxLength(10), validateNoSpecialChars, number()]}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Box>
        <Box className={classes.wrapper}>
          <TextInput
            resource={"merchants"}
            type="email"
            source="email"
            validation={{ email: true }}
            className={classes.inputMedium}
            validate={[required(), email(), maxLength(64)]}
            inputProps={{
              maxLength: 64,
            }}
          />

          <TextInput
            resource={"merchants"}
            source="supportEmail"
            className={classes.inputMedium}
            validate={[email()]}
          />
        </Box>
      </Box>
      <Box>
        <SectionTitle
          className={classes.title}
          label="resources.merchants.forms.paymentInfo"
        />
        <SelectInput
          resource={"merchants"}
          source="payoutType"
          choices={paymentType}
          optionText="value"
          optionValue="value"
          className={classes.inputMedium}
        />
        <Separator />
        <TextInput
          resource={"merchants"}
          source="payoutAcquirer"
          className={classes.inputMedium}
          validate={required30Chars}
          inputProps={{
            maxLength: 30,
          }}
        />
        <Separator />
        <TextInput
          resource={"merchants"}
          source="payoutAccountNumber"
          className={classes.inputMedium}
          validate={validateNoSpecialChars}
        />
        <Separator />
        <TextInput
          resource={"merchants"}
          label={"Account Name"}
          source="payoutAccountName"
          className={classes.inputMedium}
          validate={required30Chars}
          inputProps={{
            maxLength: 30,
          }}
        />
        <Separator />
        <TextInput
          resource={"merchants"}
          source="payoutCustomerReference"
          label={"Customer Reference"}
          className={classes.inputMedium}
          validate={required30Chars}
          inputProps={{
            maxLength: 30,
          }}
        />
        <Separator />
      </Box>
    </Grid>
  );
};
const required40Chars = [required(), maxLength(40), validateNoSpecialChars];
const required30Chars = [required(), maxLength(30), validateNoSpecialChars];
const required7Chars = [required(), maxLength(7), validateNoSpecialChars];
const requiredValidate = [required()];
const Separator = () => <Box pt="1em" />;
export default ViewDetailsForm;
